<template>
  <div class="view pa24">
    <div class="d-flex">
      <div style="display: flex">
        <el-input
            style="width: 200px!important;"
            class="w120 mb10 mr10"
            placeholder="企业名称"
            v-model="filterName"
            clearable
        />
        <el-select
              v-model="filterId"
              style="width: 200px;margin-right: 20px"
              placeholder="请选择类型"
              clearable
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.typeId"
                :label="item.name"
                :value="item.typeId"
            />
          </el-select>
        <el-button type="primary" style="height: 34px;" @click="filterNames">搜索</el-button>
      </div>
      <div style="margin-left: auto">
        <el-button class="ma" type="primary" @click="centerDialogVisible = true,upDate=1"
        >+ 新增项目
        </el-button
        >
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :total="total"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        :currentPage="currentPage"
        :loading="loading"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="recommCompanyName" align="center" label="企业名称"/>
        <el-table-column  align="center" label="logo">
          <template slot-scope="scope">
            <el-image
                style="width: 80px; height: 40px"
                :src="scope.row.logo"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column align="center" label="排序">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="数值越大，排序越靠前" placement="right-start">
              <el-input v-model="scope.row.sort" min="0"
                        @input="scope.row.sort=scope.row.sort.replace(/^0(0+)|[^\d]+/g,'')" placeholder="数值越大，排序越靠前"
                        @change="saveSort(scope.row)"/>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" align="center" label="创建时间"/>
        <el-table-column align="center" label="操作" width="300px">
          <template slot-scope="scope">
            <el-button type="primary"  plain @click="open(scope.row)"
            >编辑
            </el-button
            >
            <customPopconfirm
                class="ma ml10 mb10"
                confirm-button-text="确定"
                cancel-button-text="取消"
                @confirm="del(scope.row.recommendId)"
                title="确认删除？">
              <el-button type="danger" slot="reference" plain>删除</el-button>
            </customPopconfirm>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="新增项目"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          ref="ruleForm" :model="ruleForm" label-width="90px" :rules="rules" class="view pa24" label-position="left"
      >
        <el-form-item prop="companyTitle" label="企业名称">
          <el-input
              clearable
              v-model="ruleForm.companyTitle"
              style="width: 100%"
              placeholder="请输入企业名称"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item prop="sort" label="排序">
          <el-input
              clearable
              v-model="ruleForm.sort"
              style="width: 100%"
              placeholder="请输入排序"
              maxlength="50"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="所属类型" prop="typeId">
          <el-select
              v-model="ruleForm.typeId"
              style="width: 100%"
              placeholder="请选择类型"
              clearable
          >
            <el-option
                v-for="(item) in typeList"
                :key="item.typeId"
                :label="item.name"
                :value="item.typeId"
            />
          </el-select>
        </el-form-item>

        <el-form-item prop="coverImg" label="logo">
          <p class="ma mb10" style="color: rgb(150, 150, 150)">
            图片上传比例为1:1，支持格式png、jpg、jpeg，大小不超过1M
          </p>
          <div>
            <el-upload
                :action="uploadUrl"
                :class="{disabled: uploadDisabled2}"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :before-upload="beforeAvatarUploadImg"
                :on-success="shareUpload"
                :file-list="ruleForm.coverImg"
                :limit="1"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog
                :visible.sync="dialogVisible"
                append-to-body
                :modal-append-to-body="false"
            >
              <img width="100%" :src="dialogImageUrl" alt=""/>
            </el-dialog>
          </div>
        </el-form-item>

        <el-form-item label="关联企业">
          <el-button @click="chooseQY">+ 选择企业</el-button>
          <div class="choose_list">
            <div class="choose_item" v-for="(item1,index1) in multipleSelection" :key="index1">
              <div class="choose_left">
                <img :src="item1.companyLogo">
                <div style="flex: 1">
                  <span>{{ item1.companyName }}</span>
                  <div class="del">
                    <span></span>
                    <img src="../../../assets/images/delete_ZDY.png" alt="" @click="delQY(index1)">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="企业列表"
        :visible.sync="donationJL"
        :modal-append-to-body="false"
        width="50%"
        center
    >
      <el-table
          v-loading="loading1"
          :data="donationList"
          tooltip-effect="dark"
          style="width: 100%"
          ref="multipleTable"
          @select-all="onSelectAll"
          @selection-change="handleSelectionChange"
          @row-click="handleClickRow">
        <el-table-column type="selection"/>
        <el-table-column prop="companyName" align="center" label="企业名称"/>
      </el-table>

      <div
          style="display: flex; justify-content: flex-end; margin-top: 16px"
          v-if="donationList.length !== 0 "
      >
        <el-pagination
            background
            @size-change="handleSizeChange1"
            @current-change="handleCurrentChange1"
            :current-page="currentPage1"
            :page-sizes="[10, 30, 50, 100, 200]"
            layout="total, sizes, prev, pager, next, jumper"
            class=""
            :total="total1"
            :pager-count="5"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="donationJL = false">取 消</el-button>
        <el-button type="primary" @click="donationJL = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import customPopconfirm from "@/components/common/customPopconfirm"
import {addCompanyRecommend,queryCompanyRecommendPage,delCompanyRecommend,updateCompanyRecommend,queryCompanyRecommendById,selectTjCompanyTypePageList} from "@/api/chinaMerchants";
import {getCompanyListByAssIdByPage,} from "@/api/coupon.js";

export default {
  name: "conList",
  components: {
    commonTable,
    customPopconfirm
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      multipleTable:'multipleTable',
      inputMaxL: 10,
      centerDialogVisible: false,
      donationJL: false,
      ruleForm: {
        companyTitle: "",
        sort: '',
        projectDetails: "",
        coverImg: [],
        goodPhoto: [],
        typeId:''
      },
      dialogVisible: false,
      rules: {
        companyTitle: [{required: true, message: "请输入企业名称", trigger: "blur"}],
        sort: [{required: true, message: "请输入排序", trigger: "blur"}],
        typeId: [{required: true, message: "请选择类型", trigger: "blur"}],
        coverImg: [{required: true, message: "请上传logo"}],
      },
      userInfo: this.$store.state.loginRoot.userInfo,
      tableData: [],
      filterName: "",
      filterId:"",
      row: "",
      delGoodsT: [],
      currentPage: 1,
      loading: false,
      loading1: false,
      total: 0, //总条数
      pageNum: 1,
      pageSize: 10,
      uploadUrl: this.$store.state.uploadingUrl,
      dialogImageUrl: "",
      upDate: 1,
      recommendId: '',
      donationList: [],
      currentPage1: 1,
      total1: 0, //总条数
      pageNum1: 1,
      pageSize1: 10,
      srcList: [],
      multipleSelection: [],
      typeList:[]
    };
  },
  computed: {
    uploadDisabled2() {
      return this.ruleForm.coverImg.length !== 0;
    },
  },
  mounted() {
    this.getList()
    this.queryPage();
  },
  methods: {
    async getList() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectTjCompanyTypePageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        this.typeList = list;
        this.total = total;
      } catch (error) {
        this.typeList = []
        this.loading = false;
      }
    },
    onSelectAll() {
      this.$refs.multipleTable.clearSelection();
    },
    handleClickRow(val){
      this.$refs.multipleTable.toggleRowSelection(val);
    },
    handleSelectionChange(val) {
      console.log('00000')
      if (val.length > 1) {
        var newRows = val.filter((it, index) => {
          if (index == val.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        });
        this.multipleSelection = newRows;
      }else {
        this.multipleSelection = val
      }
    },
    async queryPage(payload) {
      let data = {
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await queryCompanyRecommendPage(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].logo) {
            a.push(list[i].logo)
          }
          list[i].createTime = this.renderTime(list[i].createTime)
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.tableData = []
        this.loading = false;
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    //搜索
    async filterNames() {
      this.currentPage = 1;
      let data = {
        recommCompanyName: this.filterName,
        typeId: this.filterId,
      };
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange(val) {
      let data = {
        recommCompanyName: this.filterName,
        typeId: this.filterId,
      };
      this.currentPage = val;
      this.queryPage(data);
    },
    //当前页查询数据条数变更
    handleSizeChange(val) {
      let data = {
        recommCompanyName: this.filterName,
        typeId: this.filterId,
      };
      this.pageSize = val;
      this.queryPage(data);
    },
    //当前页变更
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      this.get();
    },
    //当前页查询数据条数变更
    handleSizeChange1(val) {
      this.pageSize1 = val;
      this.get();
    },
    async saveSort(row) {
      if (row && row.sort != "") {
        let ajax = {
          sort: row.sort,
          recommendId: row.recommendId
        }
        const result = await updateCompanyRecommend(ajax);
        this.$message({
          type: "info",
          message: "保存成功",
        });
        this.queryPage();
      } else {
        this.$message({
          type: "error",
          message: "排序不能为空",
        });
      }
    },
    determine() {
      if (this.ruleForm.coverImg.length == 0) {
        return this.$message({
          message: "logo未上传",
          type: "warning",
          center: true,
        });
      }
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let companyId = []
            if (this.multipleSelection.length>0){
              companyId = this.multipleSelection.map((item) => item.companyId);
            }
            let data = {
              associationId: localStorage.getItem("associationId"),
              recommCompanyName: this.ruleForm.companyTitle,
              logo: this.ruleForm.coverImg && this.ruleForm.coverImg.length != 0
                  ? this.ruleForm.coverImg[0].url
                  : [],
              sort:this.ruleForm.sort,
              typeId:this.ruleForm.typeId,
              isDel:0,
              companyId:companyId.join(","),
            };
            this.$message({
              message: "正在保存",
            });
            if (this.upDate == 1) {
              await addCompanyRecommend(data);
            } else {
              data.recommendId = this.recommendId
              await updateCompanyRecommend(data);
            }
            this.centerDialogVisible = false;
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 删除
     * @param {String} val - 文章id
     */
    async del(val) {
      try {
        await delCompanyRecommend({recommendId: val});
        await this.queryPage();
      } catch (error) {
        console.log(error);
      }
    },
    /**@method 编辑
     * @param {Object} val - 当前点击行的值
     */
    async open(val) {
      this.upDate = 2
      this.centerDialogVisible = true;
      const result = await queryCompanyRecommendById({recommendId: val.recommendId});
      let data = JSON.parse(JSON.stringify(result.data));
      if ( data.companyModel ) {
        this.multipleSelection = [data.companyModel]
      }

      if (data.logo && data.logo.length !== 2 && data.logo.length !== 0) {
        this.ruleForm.coverImg = [{url: data.logo, uid: 1}];
      } else {
        this.ruleForm.coverImg = [];
      }
      this.ruleForm.companyTitle = data.recommCompanyName;
      this.ruleForm.sort = data.sort;
      this.ruleForm.typeId = data.typeId;
      this.recommendId = data.recommendId
    },

    //图片
    shareUpload(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      let arr = [];
      fileList = fileList.map((item) => {
        if (item.response) {
          arr.push({
            url: `https://jkt-one-stand.obs.cn-southwest-2.myhuaweicloud.com/${item.response.data}`,
            uid: item.uid,
          });
        } else {
          arr.push(item);
        }
      });
      this.ruleForm.coverImg = arr;
    },
    beforeAvatarUploadImg(file) {
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 1MB!");
      }
      return isLt2M;
    },
    handleRemove(file, fileList) {
      this.ruleForm.coverImg = fileList;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    /**@method 模态框关闭的回调 */
    close() {
      (this.ruleForm = {
        companyTitle: "",
        coverImg: [],
        sort: ""
      }),
          this.multipleSelection = []
      this.centerDialogVisible = false;
    },
    chooseQY() {
      this.donationJL = true
      this.get()
    },
    async get() {
      let param = {
        pageSize: this.pageSize1,
        pageNum: this.currentPage1,
        associationId: localStorage.getItem("associationId"),
      }
      this.loading1 = true;
      const {data} = await getCompanyListByAssIdByPage(param);
      console.log(data)
      if (data) {
        let val = data.pageInfo;
        this.donationList = val.list
        this.total1 = val.total
        this.loading1 = false;
      } else {
        this.donationList = [];
        this.loading1 = false;
      }
    },
    delQY(index){
      this.multipleSelection.splice(index,1)
    }
  },
};
</script>

<style lang="scss" scoped>
.choose_list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 20px 2px;
}

.choose_item {
  display: flex;
  justify-content: space-between;
  width: 45%;
  box-shadow: 0px 0px 30px 0px rgba(99, 99, 99, 0.1000);
  padding: 16px;
  margin: 0 8px 26px 8px;
}

.choose_left {
  display: flex;
  width: 100%;
}

.choose_left>img {
  width: 80px;
  height: 50px;
  align-self: center;
  margin-right: 20px;
}

.choose_left > div {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.choose_left span:nth-child(2) {
  font-weight: bold;
  font-size: 17px;
}

.del {
  display: flex;
  justify-content: space-between;
}

.del > img {
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-bottom: 10px;
}
</style>



